export type ChainLink = {
  img: string
  link: string
  name: string
}
export const EXTERNAL_TESTNETS: ChainLink[] = [
  {
    name: 'Bitcon',
    img: '/images/networks/btc.png',
    link: 'https://btc.asigna.io/',
  },
  {
    name: 'Fractal',
    img: '/images/networks/ftl.png',
    link: 'https://btc.asigna.io/',
  },
  {
    name: 'Stacks',
    img: '/images/networks/stx.png',
    link: 'https://stx.asigna.io/',
  },
]

export const EXTERNAL_PRODNETS: ChainLink[] = [
  {
    name: 'Bitcon',
    img: '/images/networks/btc.png',
    link: 'https://btc.asigna.io/',
  },
  {
    name: 'Fractal',
    img: '/images/networks/ftl.png',
    link: 'https://btc.asigna.io/',
  },
  {
    name: 'Stacks',
    img: '/images/networks/stx.png',
    link: 'https://stx.asigna.io/',
  },
]
